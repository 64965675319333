import { classNamesFunction, css, Icon, Link, PrimaryButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { styles } from '../FullsizeError/FullSizeError.styles';
import { ErrorDetailComponent } from './ErrorDetailComponent';
import { IFullSizeErrorProps, IFullSizeErrorStyles } from './FullSizeError.types';
import { SaveStringButton } from './SaveStringButton';

const getClassNames = classNamesFunction<{}, IFullSizeErrorStyles>();

const loadingErrorImage = '/images/loadingError.svg';
const saveErrorFileName = 'ErrorDetails.txt';

export const FullSizeError: React.FunctionComponent<IFullSizeErrorProps> = ({
  actionButtonProps,
  message,
  correlationId,
  errorCode,
  subtitle,
  errorTitle,
  sessionId,
  userId,
  labels: {
    timeLabel,
    errorCodeLabel,
    correlationIdLabel,
    sessionIdLabel,
    userIdLabel,
    detailsLabel,
    unknownErrorTitle,
    viewMoreLabel,
    viewLessLabel,
    defaultErrorButtonActionLabel
  }
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const time = useMemo(() => new Date().toLocaleString(), []);

  const toggleCollapsedState = useCallback(() => {
    const newState = !isExpanded;
    setIsExpanded(newState);
  }, [isExpanded]);

  const getErrorData = () => {
    const errorObject = {
      title: errorTitle,
      subtitle,
      message,
      correlationId,
      errorCode,
      sessionId,
      userId
    };
    return JSON.stringify(errorObject, null, 4);
  };
  const classes = getClassNames(styles);

  const titleText = errorTitle || unknownErrorTitle;
  const toggleCollapseControlText = isExpanded ? viewLessLabel : viewMoreLabel;
  const actionButtonLabel = (actionButtonProps && actionButtonProps.actionLabel) || defaultErrorButtonActionLabel;

  const errorCodeIsUnauthorizedOrForbidden = `${errorCode}` === '403' || `${errorCode}` === '401';
  return (
    <div role="alert" aria-labelledby="ms-banner" className={classes.root} dir="ltr">
      <div className={css(classes.rootContent, 'ms-Grid')}>
        <div className={css(classes.imageContainer, 'ms-Grid-row', 'ms-sm12', 'ms-md6', 'ms-lg4')}>
          {errorCodeIsUnauthorizedOrForbidden
            ? (
            <Icon iconName="Blocked" />
              )
            : (
            <img src={loadingErrorImage} aria-hidden />
              )}
        </div>
        <div className={css(classes.errorDetailsContainer, 'ms-Grid-row', 'ms-sm12')}>
          <div className={css(classes.errorDetails, 'ms-Grid-row', 'ms-sm10', 'ms-md5')}>
            <span className={css(classes.titleText)}>{titleText}</span>
            <span className={css(classes.friendlyPromptText)}>{subtitle}</span>
            <Stack horizontal horizontalAlign={'space-around'} className={css(classes.toggleControls, 'ms-sm10')}>
              <Link onClick={toggleCollapsedState}>{toggleCollapseControlText}</Link>
              {actionButtonProps && (
                <PrimaryButton
                  text={actionButtonLabel}
                  allowDisabledFocus
                  disabled={false}
                  onClick={actionButtonProps.onActionPressed}
                />
              )}
            </Stack>
            <div className={classes.expandedView}>
              {isExpanded && (
                <Stack wrap horizontal>
                  <SaveStringButton
                    stringToSave={getErrorData()}
                    fileName={saveErrorFileName}
                    buttonProps={{ className: classes.downloadButton }}
                  />
                  <ErrorDetailComponent label={timeLabel} value={time} />
                  <ErrorDetailComponent label={sessionIdLabel} value={sessionId} />
                  <ErrorDetailComponent label={correlationIdLabel} value={correlationId} />
                  <ErrorDetailComponent label={errorCodeLabel} value={errorCode} />
                  <ErrorDetailComponent label={userIdLabel} value={userId} />
                  <ErrorDetailComponent label={detailsLabel} value={message} isFullWidth={true} />
                </Stack>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
