import { Button, IButtonProps } from '@fluentui/react';
import * as React from 'react';
import { useMemo } from 'react';

interface ISaveStringButtonProps {
  stringToSave: string;
  fileName: string;
  buttonProps?: IButtonProps;
}

const canUseMSSaveBlobFunction = window.navigator && (window.navigator as any).msSaveBlob !== undefined;

const onMsSave = (stringToSave: string, fileName: string): void => {
  const blob = new Blob([stringToSave], { type: 'text/plain' });
  (window.navigator as any).msSaveBlob(blob, fileName);
};

const getBrowserSpecificProps = (stringToSave: string, fileName: string): IButtonProps => {
  if (canUseMSSaveBlobFunction) {
    return {
      onClick: () => {
        onMsSave(stringToSave, fileName);
      }
    };
  } else {
    return {
      download: fileName,
      href: `data:text/plain;base64,${btoa(stringToSave)}`,
      target: '_blank'
    };
  }
};

export const SaveStringButton: React.FunctionComponent<ISaveStringButtonProps> = ({
  fileName,
  buttonProps,
  stringToSave
}) => {
  const memoizedGetBrowserSpecificProps = useMemo(() => getBrowserSpecificProps(stringToSave, fileName), [
    stringToSave,
    fileName
  ]);
  return <Button iconProps={{ iconName: 'Download' }} {...memoizedGetBrowserSpecificProps} {...buttonProps} />;
};
