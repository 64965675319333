import { DefaultFontStyles, ScreenWidthMaxMedium, ScreenWidthMaxSmall, ScreenWidthMinMedium } from '@fluentui/react';

import { IFullSizeErrorStyles } from './FullSizeError.types';

export const gridUnit = 8;

export const styles: IFullSizeErrorStyles = {
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },

  rootContent: {
    maxHeight: '100%',
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 2 * gridUnit,
    boxSizing: 'border-box'
  },

  imageContainer: {
    maxHeight: gridUnit * 10,
    maxWidth: gridUnit * 10,
    fontSize: gridUnit * 8,
    lineHeight: gridUnit * 10,
    marginBottom: gridUnit * 3,
    margin: '0px auto',
    textAlign: 'center'
  },

  errorDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },

  detailComponent: {
    width: '50%',
    paddingTop: gridUnit * 1,
    selectors: {
      [`@media(max-width: ${ScreenWidthMaxSmall}px)`]: {
        width: '100%'
      }
    }
  },

  errorDetails: {
    textAlign: 'center',
    position: 'relative'
  },
  titleText: {
    ...DefaultFontStyles.xLarge,
    display: 'block'
  },
  friendlyPromptText: {
    ...DefaultFontStyles.medium,
    paddingBottom: gridUnit * 4,
    display: 'block'
  },

  expandedView: {
    paddingTop: gridUnit * 4,
    position: 'relative',
    width: '95%',
    selectors: {
      [`@media(min-width: ${ScreenWidthMinMedium}px)`]: {
        maxWidth: 650
      },
      [`@media(max-width: ${ScreenWidthMaxMedium}px)`]: {
        paddingLeft: gridUnit * 2,
        paddingRight: gridUnit * 2,
        boxSizing: 'border-box'
      }
    }
  },

  downloadButton: {
    position: 'absolute',
    top: gridUnit * 4,
    right: 0,
    background: 'none',
    minWidth: gridUnit * 4,
    padding: 0
  },

  toggleControls: {
    maxWidth: 250,
    margin: '0px auto'
  }
};
