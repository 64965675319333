import { classNamesFunction, css, FontSizes, IStyle, IStyleFunctionOrObject, IStyleSet, ScreenWidthMaxSmall, Stack, styled } from '@fluentui/react';
import * as React from 'react';

import { gridUnit } from './FullSizeError.styles';

/**
 * Just a shorter alias for `IStyleFunctionOrObject` :P. This type should be
 * used when adding a `styles` property to a styled component.
 *
 * ```tsx
 * interface IComponentProps {
 *   styles: IStyles<IComponentStyles>;
 * }
 * ```
 *
 * Values of this type can be used with Office UI Fabric styling helpers.
 *
 * ```tsx
 * // A style set factory function or style set object.
 * const styles: IStyles<IComponentStyles, IComponentStyleProps>;
 *
 * // Office UI Fabric helper which creates a type `getClassNames` function.
 * const getClassNames = classNamesFunction<IComponentStyleProps, IComponentStyles>();
 *
 * // Use the `getClassNames` function to get a map fo class names from styles.
 * const classNames = getClassNames(styles);
 * ```
 */
export type IStyles<TStyleSet extends IStyleSet<TStyleSet> = {}, TStyleProps = {}> = IStyleFunctionOrObject<
TStyleProps,
TStyleSet
>;

export interface IErrorDetailComponentStyles {
  readonly root: IStyle;
  readonly value: IStyle;
  readonly label: IStyle;
}

export interface IErrorDetailComponentStyleProps {
  isFullWidth: boolean;
}

export interface IErrorDetailComponentProps {
  readonly className?: string;
  readonly styles?: IStyles<IErrorDetailComponentStyles, IErrorDetailComponentStyleProps>;
  label: string;
  value?: string | number;
  isFullWidth?: boolean;
}

const getClassNames = classNamesFunction<IErrorDetailComponentStyleProps, IErrorDetailComponentStyles>();

const ErrorDetailComponentBase: React.FunctionComponent<IErrorDetailComponentProps> = ({
  label,
  value,
  className,
  styles,
  isFullWidth = false
}) => {
  const classes = getClassNames(styles, {
    isFullWidth
  });
  if (value === undefined) {
    return null;
  }

  return (
    <Stack verticalAlign="start" horizontalAlign="start" className={css(className, classes.root)}>
      <span className={classes.label}>{label}</span>
      <span className={classes.value}>{value}</span>
    </Stack>
  );
};

export const ErrorDetailComponent = styled<
IErrorDetailComponentProps,
IErrorDetailComponentStyleProps,
IErrorDetailComponentStyles
>(ErrorDetailComponentBase, ({ isFullWidth }) => ({
  root: {
    width: isFullWidth ? '100%' : '50%',
    paddingTop: gridUnit * 1,
    selectors: {
      [`@media(max-width: ${ScreenWidthMaxSmall}px)`]: {
        width: '100%'
      }
    }
  },
  value: {
    fontSize: FontSizes.medium,
    display: 'block',
    textAlign: 'left',
    overflowWrap: 'break-word',
    wordBreak: 'break-word'
  },
  label: {
    fontSize: FontSizes.medium,
    display: 'block',
    textAlign: 'left',
    color: '#666666',
    fontWeight: 'bold'
  }
}));
